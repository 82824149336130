function unformat(value: number | string, decimal: string = "."): number {
	if (!value) {
		return 0;
	}

	value = value || 0;

	if (typeof value === "number") return value;

	decimal = decimal || ".";

	const regex = new RegExp(`[^0-9-${decimal}]`, "g");
	const unformatted = parseFloat(
		("" + value)
			.replace(/\((?=\d+)(.*)\)/, "-$1")
			.replace(regex, "")
			.replace(decimal, ".")
	);

	return !isNaN(unformatted) ? unformatted : 0;
}

function DecimalTransformer(
	source: string | number | null | undefined
): number {
	if (!source) {
		return 0;
	}

	if (typeof source === "number") {
		source = source.toString();
	}

	let float = unformat(source, ".");
	const posComma = source.indexOf(",");
	if (posComma > -1) {
		const posDot = source.indexOf(".");
		if (posDot > -1 && posComma > posDot) {
			const germanFloat = unformat(source, ",");
			if (Math.abs(germanFloat) > Math.abs(float)) {
				float = germanFloat;
			}
		} else {
			float = unformat(source, ",");
		}
	}

	return float;
}

export { DecimalTransformer };
